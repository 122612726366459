import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",

    // flexBasis: '33%',
    fontFamily: "Poppins",
    // color: 'rgb(255, 255, 230)',
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      marginTop: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "70vh",
      marginTop: "100px",
    },
  },
  colorText: {
    color: "#1B6B93",
  },
  container: {
    // textAlign: 'center',
    height: "80vh",
    maxWidth: "90vw",

    // flexWrap: 'nowrap',

    // width: '900wh',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      // flexDirection: 'column',
      marginTop: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "100px",
    },
  },
  mainText: {
    padding: "30px",
    marginBottom: "100px",
  },
  heroImage: {
    position: "relative",
    // width: '10%',
    // height: '10%',
    display: "block",
    marginBottom: "0",
    marginLeft: "150px",
    float: "right",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: "50px",
      marginBottom: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "2px",
      marginBottom: "100px",
    },
  },
  title: {
    // position: 'relative',
    // color: '#8BBCCC',

    fontSize: "3.5rem",
    lineHeight: "70px",
    fontWeight: "500",
    margin: "30px 0px",

    // backgroundColor: '#f3ec78',
    backgroundImage: "linear-gradient(95deg, #8BBCCC, #4C6793, #46531A, rgba(90, 112, 13, 0.1))", //46531A
    backgroundSize: "100%",
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-moz-text-fill-color": "transparent",

    // flexWrap: 'nowrap',
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem",
      backgroundSize: "100%",
    },
  },
  shortbio: {
    // color: '#e7e7e7', //8BBCCC
    fontSize: "1.15rem",
    margin: "10px 5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0rem",
    },
  },
  shortbiosub: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="main">
      <div className={classes.container}>
        <div>
          <div className={classes.title}>Welcome!</div>
          <div className={classes.shortbio}>
            <p /> I am a software engineer and am currently focused on developing Generative AI applications as Full Stack Web Apps and Mobile Apps using
            fine-tuning with RAG, building multi AI Agents teams, and creating AI personalized recommendations. I am also working on Prompt Engineering and
            Optimization.
            <p /> My journey has also led me into the realm of Web3 and Blockchain technologies. I've had the opportunity to develop decentralized applications
            (DApps) and integrate them with Oracles and the InterPlanetary File System (IPFS) for decentralized storage.
            <p /> I built applications leveraging a diverse range of programming languages, frameworks, and libraries: Javascript, Python, Node, React,
            LangChain, Vector Database, Smart Contracts with Solidity, Web3, Ethers and others.
            <p /> Previously, I was a mobile app developer, and a research and development engineer specializing in evaluating emerging technologies centered
            around improving patient care and hospital processes. I developed prototypes to showcase and validate ideas.
            <p /> I believe in continuous growth, and am excited about opportunities to contribute to meaningful projects and advance my skills in the
            ever-evolving tech landscape.
            {/*<p className={classes.shortbiosub}>* Certificates are attached under the Education section </p>*/}
          </div>
        </div>
        <div className={classes.heroImage}>
          <img src="/assets/main-image.png" alt="tech"></img>
        </div>
      </div>
    </div>
  );
}
