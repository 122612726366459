import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Header from './components/Header';
import Main from './components/Main';
import Projects from './components/Projects';
import Education from './components/Education';
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100vh',
    // backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/bg.jpg'})`,
    backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundColor: 'rgb(57, 57, 57)',
    // backgroundColor: 'rgb(165, 165, 165)',
    margin: 0,
    padding: 0,
  },
}));

export default function App() {
  const classes = useStyles();


  return (

    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Main />
      <Projects />
      <Education />
      <Footer />
    </div>

  );
}


