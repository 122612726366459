const projects = [
  {
    title: "Hierarchical AI Agent Teams",
    description: "Showcases how AI Agents can perform tasks using tools in a hierarchical team",
    imageUrl: process.env.PUBLIC_URL + "/images/hierarchical-agents-teams.png",
    tools: "Python • LangChain • LangGraph • Tavily",
    link: "",
    github: "https://github.com/deep-blue-sea-7/hierarchical-ai-agent-teams/blob/main/hierarchical_agent_teams.ipynb",
    website: "",
    time: 1500,
  },
  {
    title: "Chatbot to query PDF Files",
    description: "AI app uses RAG with PDF upload to provide context for prompting ",
    imageUrl: process.env.PUBLIC_URL + "/images/rag_pdf_app.png",
    tools: "Python • AstraDB • Langchain • OpenAI",
    link: "",
    github: "https://github.com/deep-blue-sea-7/rag-chatbot-for-pdf",
    website: "https://rag-chatbot-for-pdf.streamlit.app",
    time: 1500,
  },
  {
    title: "Website Context Retrieval",
    description: "AI app to query data with context from any website once it is indexed in LanceDB",
    imageUrl: process.env.PUBLIC_URL + "/images/ai-website-front_page.png",
    tools: "Node • Next • LanceDB • Tailwind • OpenAI",
    link: "",
    github: "https://github.com/deep-blue-sea-7/rag-chatbot-for-websites",
    website: "https://rag-chatbot-for-websites.vercel.app",
    time: 1500,
  },
  {
    title: "AI Personalized Real Estate Agent",
    description: "This AI app transforms standard listings into personalized recommendations",
    imageUrl: process.env.PUBLIC_URL + "/images/personalized-real-estate-agent.png",
    tools: "Python • LangChain • ChromaDB • OpenAI",
    link: "",
    github: "https://github.com/deep-blue-sea-7/ai-personalized-real-estate-agent",
    website: "",
    time: 1500,
  },
  {
    title: " AI Image Editing with Inpainting ",
    description: "Segment Anything Model (SAM) and Stable Diffusion to “inpaint” images",
    imageUrl: process.env.PUBLIC_URL + "/images/dragon_with_inpainting.png",
    tools: "ThirdWeb • Ethers • Tailwind • Solidity",
    link: "",
    github: "https://github.com/deep-blue-sea-7/ai-photo-editing-with-inpainting",
    website: "",
    time: 1500,
  },
  // {
  //   title: "Parameter Efficient Fine Tuning",
  //   description: "Program applying Lightweight Fine-Tuning to a Foundation Model with LoRA",
  //   imageUrl: process.env.PUBLIC_URL + "/images/flake.png",
  //   tools: "Pyhton • PEFT • LoRA",
  //   link: "",
  //   github: "https://github.com/deep-blue-sea-7/parameter-efficient-fine-tuning/blob/main/LightweightFineTuning.ipynb",
  //   website: "",
  //   time: 1500,
  // },
  {
    title: "DApp: Crypto Crowdfunding",
    description: "Responsive DApp for crowdfunding through creating and funding campaigns",
    imageUrl: process.env.PUBLIC_URL + "/images/crowdfunding-dapp-home.png",
    tools: "ThirdWeb • Ethers • Tailwind • Solidity",
    link: "",
    github: "https://github.com/deep-blue-sea-7/crowdfunding-dapp?tab=readme-ov-file#a-crypto-crowdfunding-dapp",
    website: "https://crowdfunding.inovantech.com",
    time: 1500,
  },
  {
    title: "DApp: Electronic Health Records",
    description: "A Blockchain EHR system allowing patients to grant doctors access to their record",
    imageUrl: process.env.PUBLIC_URL + "/images/ehr-frontpage.png",
    tools: "Web3 • Nextjs • JS • Truffle • Solidity",
    link: "bma",
    github: "https://github.com/deep-blue-sea-7/electronic-health-records-dapp#a-blockchain-medical-records-system",
    website: "",
    time: 1500,
  },
  {
    title: "DApp: Flight Insurance",
    description: "This DApp offers flight delay insurance to airline passengers",
    imageUrl: process.env.PUBLIC_URL + "/images/dapp-insur-frontend.png",
    tools: "Web3 • Node • JS • Truffle • Solidity",
    link: "https://github.com/deep-blue-sea-7/flightsurety-dapp",
    github: "https://github.com/deep-blue-sea-7/flightsurety-dapp?tab=readme-ov-file#flightsurety-flight-insurance-dapp",
    website: "",
    time: 1500,
  },
  {
    title: "DApp: Supply Chain",
    description: "Supply Chain DApp for tracking products and verifying thir origin authenticity",
    imageUrl: process.env.PUBLIC_URL + "/images/dapp-sc-frontend.png",
    tools: "Web3 • Node • IPFS • Truffle • Solidity",
    link: "https://github.com/deep-blue-sea-7/supply-chain-tracking-dapp",
    github: "https://github.com/deep-blue-sea-7/supply-chain-tracking-dapp#a-supply-chain-dapp--product-authenticity-tracking",
    website: "https://bafybeigmu2extibclb2mll5oguwjsnucuaewk6fcvsgn3gpnkunchkedji.ipfs.w3s.link",
    time: 1500,
  },
  {
    title: "DApp: Real Estate",
    description: "Buy and sell properties on the blockchain while going through appraisals, escrow,...",
    imageUrl: process.env.PUBLIC_URL + "/images/dapp-re-frontend.png",
    tools: "Openzeppelin • Hardhat • Solidity • IPFS",
    link: "https://github.com/deep-blue-sea-7/real-estate-dapp",
    github: "https://github.com/deep-blue-sea-7/real-estate-dapp#real-estate-dapp",
    website: "",
    time: 1500,
  },
  {
    title: "Wallet Chrome Extension",
    description: "Crypto Wallet extension which implements some of Metamask features",
    imageUrl: process.env.PUBLIC_URL + "/images/wallet-frontpage.png",
    tools: "Moralis • Ethers • Node • Axios",
    link: "https://github.com/deep-blue-sea-7/crypto-wallet-extension",
    github: "https://github.com/deep-blue-sea-7/crypto-wallet-extension#allwallet-a-web-crypto-wallet-extension",
    website: "",
    time: 1500,
  },
  {
    title: "Shopping Cart Template",
    description: "Front-end for a fullstack cart application",
    imageUrl: process.env.PUBLIC_URL + "/images/cart.png",
    tools: "Angular • Bootstrap • CSS • TS",
    link: "https://cart.inovantech.com/",
    github: "https://github.com/deep-blue-sea-7/frontend-app-mystore#mystore",
    website: "https://cart.inovantech.com/",
    time: 1500,
  },
  {
    title: "Data Analytics: Twitter Wordcloud",
    description: "Data Analyticss: Uses a dataset of tweets. Builds two wordcloud image outputs. Published to Kaggle",
    imageUrl: process.env.PUBLIC_URL + "/images/blue-bird-twitter-wordcloud.png",
    tools: "Pyhton • Jupypter Notebook",
    link: "https://github.com/deep-blue-sea-7/data-analytics-custom-twitter-wordcloud/blob/main/custom-twitter-wordcloud.ipynb",
    github: "https://github.com/deep-blue-sea-7/data-analytics-custom-twitter-wordcloud/blob/main/custom-twitter-wordcloud.ipynb",
    website: "",
    time: 1500,
  },
  {
    title: "Modern Bank Web Site",
    description: "Responsive React JS application. Figma Design: https://rb.gy/0yped",
    imageUrl: process.env.PUBLIC_URL + "/images/bank.png",
    tools: "Vite • Tailwind • CSS • JS",
    link: "https://bank.inovantech.com/",
    github: "",
    website: "https://bank.inovantech.com/",
    time: 1500,
  },
  {
    title: "Travel Web Site: Paradis Island",
    description: "Showcases animation and collapsible cards",
    imageUrl: process.env.PUBLIC_URL + "/images/island.png",
    tools: "React • Material UI • CSS",
    link: "https://island.inovantech.com/",
    github: "",
    website: "https://island.inovantech.com/",
    time: 1500,
  },
  // {
  //   title: "Amazon Product Scraper",
  //   description: "Custom API endpoints to retireve Amazon products details, reviews, and offers. Published to RapidAPI",
  //   imageUrl: process.env.PUBLIC_URL + "/images/amazon-scaper.png",
  //   tools: "Node • Express • API",
  //   link: "https://rapidapi.com/dreamscapetorealitydesign/api/amazon-data-scraper132",
  //   github: "",
  //   website: "https://rapidapi.com/dreamscapetorealitydesign/api/amazon-data-scraper132",
  //   time: 1500,
  // },
  // {
  //   title: "Cryptoverse Web Site",
  //   description: "Search for cryptocurrencies info and news. Fetch data from multiple sources using RapidAPI ",
  //   imageUrl: process.env.PUBLIC_URL + "/images/cryptoverse.png",
  //   tools: "React • Redux • Ant Design • CSS",
  //   link: "https://cryptoverse.inovantech.com/",
  //   github: "",
  //   website: "https://cryptoverse.inovantech.com/",
  //   time: 1500,
  // },
];

export default projects;
