import React, { useEffect, useState } from 'react';
import './../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link as Scroll } from 'react-scroll';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    // flexBasis: '33%',
    fontFamily: 'Poppins',
  },
  noPadding: {
    margin: '0',
  },
  appbar: {
    background: 'none',
    // background: 'rgba(57, 57, 57, 0.8)',
    // background: 'rgba(57, 57, 57)',
    // opacity: '0.1',
    // margin: '0 0 100 100',
  },
  appbarWrapper: {
    width: '100%',
    margin: '0 auto',
  },
  appbarTitle: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'left',
    color: '#8BBCCC'
  },
  appbarMenu: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'left',
    color: '#8BBCCC'
  },
  icon: {
    color: '#4C6793', //4C6793 1B6B93
    fontSize: '2.8rem',
    padding: '0',
    margin: '0',
  },
  colorText: {
    color: '#1B6B93',
  },
  container: {
    // textAlign: 'center',
    height: '80vh',
    flexWrap: 'nowrap',

    // width: '700wh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '30px',
  },
  
  menu: {
    background: '#4C6793',
  },
  menuItem: {
    color: '#8BBCCC',
    fontFamily: 'Poppins',
    fontSize: '1rem',
  },
}));

export default function Header() {
  const classes = useStyles();
  const [profileMenu, scrollToSection] = useState(null);

  const [myTheme, setMyTheme] = useState('dark');

  // Set the initial theme to dark
  if (localStorage.getItem('theme') === null) {
    window.localStorage.setItem('theme', 'dark');
  }

  const toggleTheme = () => {
    let theme = localStorage.getItem('theme');

    if (theme === 'light') {
      window.localStorage.setItem('theme', 'dark')
      setMyTheme('dark');
    } else {
      window.localStorage.setItem('theme', 'light')
      setMyTheme('light');
    }
  };
  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');

    // document.body.className = myTheme;
    document.body.className = localTheme;
  }, [myTheme]);


  return (
    <div className={classes.root} id="header">
      <AppBar className={classes.appbar} elevation={7}>
        <Toolbar className={classes.appbarWrapper}>
          <div className={classes.appbarMenu}>
            <IconButton sx={{ width: 200, padding: 1, margin: 2 }} onClick={e => scrollToSection(e.currentTarget)}> 
              <SortIcon className={classes.icon} />
            </IconButton>
          </div>
          <div class="switch" onClick={toggleTheme}>
            <div class="flicker"></div>
            <div class="moon"></div>
          </div>

        <Menu
            classes={{ paper: classes.menu }}
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => scrollToSection(null)}
            disableAutoFocusItem
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
            <MenuItem
                className={classes.menuItem}
                component={Scroll}
                onClick={() => scrollToSection(null)}
                to="main" smooth={true}
                >
                Home
            </MenuItem>        
            <MenuItem
                className={classes.menuItem}
                component={Scroll}
                onClick={() => scrollToSection(null)}
                to="projects" smooth={true}
                >
                Projects
            </MenuItem>
            <MenuItem
                className={classes.menuItem}
                component={Scroll}
                onClick={() => scrollToSection(null)}
                to="education" smooth={true}
                >
                Education
            </MenuItem>
        </Menu>
         
        </Toolbar>
      </AppBar>
    </div>
  );
}
